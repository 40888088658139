import React from "react";
import { Link, withPrefix } from "gatsby";
import PropTypes from "prop-types";

const HomeIcons = ({
  data,
  alignLeft = false,
  hasTitle = false,
  hasDescription = true,
  title = "Section title",
  hasBottomRadius = false,
  showButton = false,
  buttonLabel = "Get a smarter quote",
  buttonLink = "/",
}) => {
  return (
    <div
      className={`nu-icons-container ${hasBottomRadius ? "bottom-radius" : ""}`}
    >
      <div className="content nu-c">
        {hasTitle && <h1 className="nu-icon--title">{title}</h1>}
        <div className="nu-icons">
          {data.map(({ id, src, title, desc }) => (
            <div
              key={id}
              className={`nu-icon--item ${alignLeft ? "text-left" : ""}`}
            >
              <img key={id} src={withPrefix(src)} alt={title} loading="lazy" />
              <span>{title}</span>
              {hasDescription && <div>{desc}</div>}
            </div>
          ))}
        </div>
        {showButton && (
          <Link className="nu-cta cta cta-mirror w-button" to={buttonLink}>
            {buttonLabel}
          </Link>
        )}
      </div>
    </div>
  );
};

HomeIcons.propTypes = {
  data: PropTypes.array.isRequired,
  alignLeft: PropTypes.bool,
  hasTitle: PropTypes.bool,
  hasDescription: PropTypes.bool,
  title: PropTypes.string,
  hasBottomRadius: PropTypes.bool,
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default HomeIcons;
