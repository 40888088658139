import React from "react";

const partners = [
  {
    id: "partner-1",
    src: "images/AH1.png",
    alt: "https://allanhall.com.au/",
  },
  {
    id: "partner-2",
    src: "images/logo-trident-financial-group.png",
    alt: "https://tridentfinancial.com.au/",
  },
  {
    id: "partner-3",
    src: "images/UHY-Logo-2.png",
    alt: "https://uhyhnsydney.com.au/",
  },
  {
    id: "partner-4",
    src: "images/illumin8.png",
    alt: "https://www.illumin8.com.au/",
  },
  {
    id: "partner-5",
    src: "images/maclean-partners-new-600w.png",
    alt: "https://www.macleanpartners.com.au/",
  },
  {
    id: "partner-6",
    src: "images/bentleys.svg",
    alt: "https://www.bentleys.com.au/",
  },
  {
    id: "partner-7",
    src: "images/hlb.svg",
    alt: "https://hlb.com.au/",
  },
];

const renderPartnerImgWidth = (id) => {
  if (id === "partner-6") {
    return { width: "140px" };
  }

  if (id === "partner-7") {
    return { width: "90px" };
  }
};

const HomePartners = () => {
  return (
    <div className="content nu-c">
      <div className="nu-partner">
        {partners.map(({ id, src, alt }) => (
          <div
            key={id}
            className="nu-partner--image"
            style={renderPartnerImgWidth(id)}
          >
            <a href={alt} target="_blank" rel="noopeneer noreferrer">
              <img key={id} src={src} alt={alt} loading="lazy" />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePartners;
