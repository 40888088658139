import React, { useState } from "react";

const HomeSmarter = () => {
  const [leftActive, setLeftActive] = useState(true);

  return (
    <div className="content nu-alt-c">
      <div className="nu-btn-group">
        <div>
          <button
            type="button"
            className={leftActive ? "active" : ""}
            onClick={() => setLeftActive(true)}
          >
            For partners
          </button>
          <button
            type="button"
            className={leftActive ? "" : "active"}
            onClick={() => setLeftActive(false)}
          >
            For Taxpayers
          </button>
        </div>
      </div>
      <div className={`nu-smarter-txt ${leftActive ? "d-block" : "d-none"}`}>
        <h1>Smarter audit protection for your clients</h1>
        <p>
          Tax audit insurance that delivers personalised and fairer policies,
          pricing and protection.
        </p>
        <img
          src="./images/smarter.png"
          alt="Smarter audit protection for your clients"
          loading="lazy"
        />
      </div>
      <div className={`nu-smarter-txt ${leftActive ? "d-none" : "d-block"}`}>
        <h1>
          Tax audit insurance without the <em>icky</em> bits
        </h1>
        <p>
          Personalised tax audit insurance that puts the insurance policy holder
          back in control.
        </p>
        <img
          src="./images/smarter.png"
          alt="Smarter audit protection for your clients"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default HomeSmarter;
