import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import HomeBanner from "../components/HomeBanner";
import HomePartners from "../components/HomePartners";
import HomeSmarter from "../components/HomeSmarter";
import HomeIcons from "../components/HomeIcons";
import HomeMan from "../components/HomeMan";
import HomePrivacy from "../components/HomePrivacy";
import HomeClients from "../components/HomeClients";

const icons = [
  {
    id: "icon-1",
    src: "images/ac-icon-1.svg",
    title: "Direct client protection",
    desc: "Protection against tax audit defence fees that covers your taxpayers directly.",
  },

  {
    id: "icon-2",
    src: "images/ac-icon-2.svg",
    title: "Flexible policy options",
    desc: "Customisable coverage options to meet the unique needs of taxpayers and groups.",
  },
  {
    id: "icon-3",
    src: "images/ac-icon-3.svg",
    title: "Faster digital onboarding",
    desc: "Speedy online quoting for getting covered and staying up to date.",
  },
  {
    id: "icon-4",
    src: "images/ac-icon-4.svg",
    title: "No data sharing",
    desc: "Practices can engage clients and avoid sharing any confidential information.",
  },
  {
    id: "icon-5",
    src: "images/ac-icon-5.svg",
    title: "Zero expense to the practice",
    desc: "Simple tax audit insurance with no added expenses for the practice.",
  },
  {
    id: "icon-6",
    src: "images/ac-icon-6.svg",
    title: "Ongoing authorised support",
    desc: "Expert customer support available to policyholders from our friendly team.",
  },
];

const IndexPage = ({ data }) => {
  const { banner, testimonials } = data;
  return (
    <Layout
      bg="home-bg"
      banner={
        <HomeBanner
          subheader={banner.frontmatter.subheader}
          header={banner.frontmatter.header}
          header2={banner.frontmatter.header2}
          ctalinktext={banner.frontmatter.ctalinktext}
          socialproof={banner.frontmatter.socialproof}
        />
      }
    >
      <HomePartners />
      <HomeSmarter />
      <HomeIcons data={icons} hasBottomRadius />
      <HomeMan
        textDark
        title="AuditCover has evolved to reflect the needs of today’s accounting
            industry."
        desc="We take care of the entire Tax Audit insurance workflow for you,
            making life easier for both you and your clients."
      />
      <HomePrivacy />
      <HomeClients data={testimonials?.edges} />
    </Layout>
  );
};

export default IndexPage;

export const contentQuery = graphql`
  query {
    banner: markdownRemark(
      frontmatter: { templateKey: { eq: "content-banner" } }
    ) {
      id
      frontmatter {
        subheader
        header
        header2
        ctalinktext
        socialproof
      }
    }
    testimonials: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "testimonials" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            photo {
              publicURL
            }
            person
            company
            type
            title
            contents
          }
        }
      }
    }
  }
`;
