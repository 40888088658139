import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const HomeBanner = () => {
  return (
    <div className="banner">
      <div className="content">
        <div className="banner-wr">
          <div className="banner-txt text-white nu-c">
            <h1>Smarter tax audit insurance</h1>
            <p className="banner-p text-white">
              Personalised tax audit insurance that is simple, quick and with no
              hidden fees
            </p>
            <div className="banner-btn-group">
              <a
                href="/forms/contact-us/"
                className="cta cta-top long w-button cta-top-mirror"
              >
                Get a quote
              </a>
              <Link
                to="/forms/partner-contact-form/"
                className="cta long w-button cta-top-mirror btn-secondary"
              >
                Request a demo
              </Link>
            </div>
          </div>
          <div className="banner-img nu-c">
            <div>
              <div
                style={{
                  borderRadius: "50px 50px 50px 0px",
                  overflow: "hidden",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeBanner.propTypes = {
  subheader: PropTypes.string,
  header: PropTypes.string,
  header2: PropTypes.string,
  ctalinktext: PropTypes.string,
  socialproof: PropTypes.string,
};

HomeBanner.defaultProps = {
  subheader: "Tax audit insurance made easy",
  header: "Give your clients the tax audit protection they need",
  header2: "and reduce the workload for your practice.",
  ctalinktext: "Book  your obligation-free consultation",
  socialproof: "TAX AGENTS AND ACCOUNTANTS USING AUDITCOVER:",
};

export default HomeBanner;
