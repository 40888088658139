import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const HomeMan = ({
  textDark = false,
  title,
  desc,
  hasBottomRadius = false,
  hasButton = false,
  buttonLabel = "Get a smarter quote",
  buttonLink = "/",
}) => {
  const classes = textDark ? "text-black" : "text-white";

  return (
    <div
      className={`nu-man ${hasBottomRadius ? "bottom-radius" : ""} ${
        textDark ? "" : "woman"
      }`}
    >
      <div className="content nu-c">
        <div className={`nu-man-txt ${classes}`}>
          <h1>{title}</h1>
          <p>{desc}</p>
          {hasButton && (
            <Link
              aria-current="page"
              className="nu-man--btn cta cta-mirror w-button"
              to={buttonLink}
            >
              {buttonLabel}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

HomeMan.propTypes = {
  textDark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  hasBottomRadius: PropTypes.bool,
  hasButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
};

export default HomeMan;
