import React from "react";

const HomePrivacy = () => {
  return (
    <div className="nu-privacy">
      <div className="content nu-c">
        <div className="nu-privacy-txt">
          <h1>Privacy and security at the heart of everything we do</h1>
          <p>
            AuditCover products and processes are designed around keeping your
            data safe. We allow customers to opt-in to sharing their data, and
            only retain the data necessary for your insurance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomePrivacy;
